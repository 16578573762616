<template>
  <ul class="multi-select">
    <li class="inline">
      <button @click.stop="showOptions" class="filter--button">
        <template v-if="selectedItems.size">
          <strong class="grey--text">{{ type }}:</strong>&nbsp;
        </template>
        <span :class="{ 'grey--text': !selectedText }">{{
          ` ${camelToSentence(selectedText) || selectPrompt}`
        }}</span>
      </button>

      <button
        v-if="selectedItems.size"
        @click.stop="resetOptions"
        class="filter--button filter--cancel"
      >
        <i class="fas fa-times-square"></i>
      </button>
    </li>

    <!-- drop-down menu -->
    <li v-if="showingOptions" class="list list--multi-select">
      <!-- Filters -->
      <div
        v-for="filterOption in filterOptions"
        :class="filterClass(filterOption)"
        :key="filterOption"
        @click.stop="onFilterSelect(filterOption)"
      >
        <i :class="checkbox(filterOption)" />
        <span class="filter--option__label">
          {{ camelToSentence(filterOption) }}
        </span>
      </div>

      <!-- Filter controls (apply, cancel) -->
      <div class="form--row">
        <button
          class="button--cancel outline wide"
          @click.stop="cancelSelection"
        >
          <span class="error--text">Cancel</span>
        </button>

        <button
          class="button--confirm outline wide"
          @click.stop="applySelection"
        >
          <span class="link">Apply</span>
        </button>
      </div>
    </li>
  </ul>
</template>

<script>
import { camelToSentence } from "../utilities";
import KeyboardEventsMixin from "./mixins/keyboard-events.mixin";

export default {
  name: "MultiSelectList",

  mixins: [KeyboardEventsMixin],

  props: {
    filterOptions: { type: Array, required: true },
    selectPrompt: { type: String, default: "Select a filter:" }
  },

  data: () => ({
    selectedItems: new Set(),
    pendingSelections: new Set(),
    showingOptions: false
  }),

  computed: {
    /** @override {`KeyboardEventsMixin`} Allow exit on keyboard 'escape' event */
    listeners() {
      return [["keydown", this.onKeyEvent, false]];
    },
    selectedText() {
      return [...this.selectedItems].map(camelToSentence).join(", ");
    },
    type() {
      return new RegExp("^Sort").test(this.selectPrompt) ? "Sort" : "Filter";
    }
  },

  methods: {
    applySelection() {
      this.selectedItems = new Set([...this.pendingSelections]);
      this.pendingSelections = new Set();
      this.emitSelection();
      this.cancelSelection();
    },

    cancelSelection(/* e: HTMLClickEvent? */) {
      this.showingOptions = false;
      this.pendingSelections = new Set();
      this.detachListeners();
    },

    checkbox(option) {
      const { pendingSelections, selectedItems } = this;
      const src =
        pendingSelections.size > 0 ? pendingSelections : selectedItems;
      return src.has(option)
        ? "fas fa-check-square success--text"
        : "far fa-square grey--text";
    },

    emitSelection() {
      return this.$emit("filters-changed", [...this.selectedItems]);
    },

    filterClass(option) {
      const { pendingSelections, selectedItems } = this;
      const src =
        pendingSelections.size > 0 ? pendingSelections : selectedItems;
      const state = src.has(option) ? "active" : "inactive";
      return `filter filter--option justify wide ${state}`;
    },

    camelToSentence,

    onFilterSelect(filter) {
      const { pendingSelections, selectedItems } = this;
      const next =
        pendingSelections.size === 0
          ? new Set([...selectedItems])
          : pendingSelections;

      if (next.has(filter)) next.delete(filter);
      else next.add(filter);

      // Update object reference
      this.pendingSelections = new Set([...next]);
    },

    resetOptions() {
      this.selectedItems = new Set();
      this.pendingSelections = new Set();
      this.applySelection();
    },

    showOptions() {
      if (this.showingOptions) return;
      this.showingOptions = true;
      this.attachListeners();
    },

    onKeyEvent({ key }) {
      if (key === "Escape") {
        this.resetOptions();
        this.cancelSelection();
      }
    }
  }
};
</script>

<style lang="scss" src="./MultiSelectList.scss"></style>
